export const scrollToBottom = (elem) => {
    if (elem) {
        elem.scrollTop = elem.scrollHeight;
    }
};

export const bodyClasses = (props) => {
    const {layout, orientation, device, os, type} = props.viewport || {};
    return {
        desktop: layout === "desktop",
        mobile: layout === "mobile",
        portrait: orientation === "portrait",
        landscape: orientation === "landscape",
        touch: device === "touch",
        "no-touch": device === "no-touch",
        [`device-${os}`]: os,
        [`device-${type}`]: type
    };
};
