import {observable} from "@legendapp/state";
import _ from "lodash";
import {withRouter} from "next/router";
import React from "react";

export const scrollState$ = observable({
    scrollPosition: 0,
    scrollPercentage: 0
});

class Adapter extends React.Component {
    constructor(props) {
        const routes = props.routes || [];

        super(props);
        // this.parser = new UAParser();
        // this.parser_result = this.parser.getResult();
        this.lookup_routes = routes.reduce(
            (acc, entry) => {
                acc.names[entry.path] = entry.name;
                acc.paths[entry.name] = entry?.rewrite || entry.path;
                acc.props[entry.name] = entry.props || {};
                if (entry?.alt) {
                    acc.names[entry.alt] = entry.name;
                }
                return acc;
            },
            {paths: {}, names: {}, props: {}}
        );

        this.state = {
            maoosi: undefined,
            window: undefined
        };
    }

    clickToPage = (name, params) => {
        return (e) => {
            this.navigateToPage(name, params);
            return false;
        };
    };

    navigateToPage = (name, params) => {
        const {router} = this.props;
        const newRoute = this.getRouteLink(name, params);
        // need to build the path here...
        router.push(newRoute);
    };

    resolvePath = (url, ids = {}) => {
        const path = _.keys(ids).reduce((acc, id) => {
            acc = _.replace(acc, `[${id}]`, ids[id]);
            return acc;
        }, url);
        // console.log(`RESOLVED PATH`, path);

        return path;
    };

    getRouteName = (router) => {
        const path = router.pathname;
        return this.lookup_routes.names[path] || path;
    };

    getPathProps = (name) => {
        return this.lookup_routes.props[name] || {};
    };

    getRouteLink = (name, params) => {
        const newRoute = this.lookup_routes.paths[name];
        if (!newRoute) return null;
        const path = this.resolvePath(newRoute, params);
        return path;
    };

    scrollToTop = () => {
        const {window} = this.state;
        if (window?.scroll && typeof window.scroll === "function") {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "auto"
            });
        }
    };

    handleRouteChange = (url) => {
        // console.log('Path changed to: ', url)
        // this.scrollToTop()
    };

    refreshRoute = () => {
        const {router} = this.props;
        if (router) {
            router.replace(router.asPath, undefined, {scroll: false});
        }
    };

    resize = () => {
        const {window} = this.state;
        if (window) {
            this.forceUpdate();
            // console.log("REFRESH");
        }
    };

    async componentDidMount() {
        window.addEventListener("resize", this.resize);
        const maoosi = await import("@maoosi/device.js");
        this.setState({maoosi, window});
        // router.events.on('routeChangeComplete', this.handleRouteChange)
        window.addEventListener("scroll", this.listenToScroll);
    }

    componentWillUnmount() {
        // router.events.off('routeChangeComplete', this.handleRouteChange)
        window.removeEventListener("scroll", this.listenToScroll);
        window.removeEventListener("resize", this.resize);
    }

    getViewport = () => {
        return this.calcScreenWidthHeightDevice();
    };

    calcScreenWidthHeightDevice = () => {
        const {layout_flags, router, enableScaling} = this.props;
        const {window, maoosi} = this.state;
        const routeName = this.getRouteName(router);
        const flags = layout_flags?.[routeName] || {};

        // console.log(`CALC FLAGS`, layout_flags, flags, router, routeName);

        // orientation: 0 landscape, 1 portrait
        let viewport = {
            width: 1920,
            height: 1080,
            layout: "desktop",
            orientation: "landscape",
            innerWidth: 1920,
            innerHeight: 1080,
            browser: null,
            os: null,
            hd: 1,
            scaling: enableScaling
        };

        if (window) {
            const innerWidth = window?.innerWidth || 1920;
            const innerHeight = window?.innerHeight || 1080;
            const width = document?.documentElement?.clientWidth || 1920;
            const height = document?.documentElement?.clientHeight || 1080;
            const device = !!navigator.maxTouchPoints ? "touch" : "no-touch";
            const orientation = width < height ? "portrait" : "landscape";
            const mobileWidth = orientation === "portrait" ? width : height;
            const layout = mobileWidth <= 480 ? "mobile" : "desktop";

            viewport = _.merge(viewport, {
                width,
                height,
                innerWidth,
                innerHeight,
                layout: layout,
                orientation: orientation,
                device: device,
                mobile: device == "touch",
                hd: _.toInteger(height) === _.toInteger((width / 16) * 9) ? 1 : 0,
                scaling: enableScaling
            });
        }

        if (flags) {
            viewport = _.merge(viewport, flags);
            const existing = `${viewport.layout}.${viewport.orientation}`;
            const convert = viewport?.convert?.[existing];
            if (_.isString(convert)) {
                const [alt_layout, alt_orientation] = convert.split(".");
                viewport.layout = alt_layout;
                viewport.orientation = alt_orientation;
            }
        }

        const calc_font_size = this.calcFontSize(viewport);

        viewport.size = calc_font_size;
        const host = window?.location?.host || "";
        const protocol = window?.location?.protocol;
        viewport.host = host;
        viewport.protocol = protocol;
        viewport.localhost = _.includes(host, "localhost");
        viewport.browser = maoosi?.device?.browser;
        viewport.os = maoosi?.device?.deviceOS;
        viewport.type = maoosi?.device?.deviceType;
        viewport.url = `${viewport.protocol}//${viewport.host}`;

        return viewport;
    };

    calcFontSize = (viewport) => {
        const {enableScaling, ignoreScaling, router} = this.props;
        const default_font_size = 16;
        if (!enableScaling || viewport?.scaling == false) return default_font_size;

        const routeName = this.getRouteName(router);
        const routeProps = this.getPathProps(routeName);
        if (routeProps?.disableScaling) return default_font_size;
        if (_.includes(ignoreScaling || [], routeName)) return default_font_size;

        const {width, height, layout, orientation} = viewport;

        const scales = {
            "desktop.landscape": 1440,
            "desktop.portrait": 810,
            "mobile.landscape": 1024,
            "mobile.portrait": 480
        };

        const scaled_width = scales[`${viewport.layout}.${viewport.orientation}`];
        // const scaled_width = 1440;
        const calc_font_size = (width / scaled_width) * default_font_size;

        // console.log(`CALC FONT SIZE`, orientation, width, scaled_width, default_font_size, calc_font_size);
        return calc_font_size;
    };

    listenToScroll = () => {
        const {window} = this.state;
        if (window && typeof document !== "undefined" && document) {
            const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            const scrolled = winScroll / height;
            const position = window && window.pageYOffset ? window.pageYOffset : 0;
            scrollState$.set({
                scrollPosition: position,
                scrollPercentage: scrolled * 100
            });
        } else {
            scrollState$.set({
                scrollPosition: 0,
                scrollPercentage: 0
            });
        }
    };

    render() {
        const {enableMobile} = this.props;
        const {window} = this.state;
        const viewport = this.getViewport();
        const {layout, size} = viewport;
        const root = window && document ? document.documentElement : null;

        if (root) {
            // const vhValue = `${viewport.height * 0.01}px`;
            // document.documentElement.style.setProperty("--vh", vhValue);
            root.style.fontSize = `${size}px`;
            // console.log("CALC UPDATE", size);
        }

        return <div className={classnames(`adaptive-wrapper`, {root})}>{this.renderAdaptive(viewport)}</div>;
    }

    renderAdaptive = (viewport) => {
        const {render, router} = this.props;

        const routeName = this.getRouteName(router);

        const additional_props = {
            routeName,
            routeParams: router.query,
            routePath: router.asPath,
            pathProps: this.getPathProps(routeName),
            viewport,
            refreshRoute: this.refreshRoute,
            getRouteLink: this.getRouteLink,
            clickToPage: this.clickToPage,
            navigateToPage: this.navigateToPage
        };

        // console.log(`ROUTER`, additional_props.routeName, additional_props.routePath);

        const all_props = {
            ...this.props,
            ...additional_props,
            adapter: additional_props
        };

        return render(all_props);
    };
}

export default withRouter(Adapter);
