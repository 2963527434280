import {enableReactUse} from "@legendapp/state/config/enableReactUse";
import {DateTime} from "luxon";
import React from "react";
import "theme/react.scss";
import {routes} from "../config/paths";
import AdapterApp from "../core/app";
import {clockState$} from "../core/states";
// required to leverage state system, just activate once per app
enableReactUse();
// entry point
class App extends React.Component {
    constructor(props) {
        super(props);
        this.listeners = [];
        this.state = {
            auth: null
        };
    }

    componentDidMount() {
        this.timer = setInterval(() => {
            this.updateClock();
        }, 1000);
    }

    updateClock = () => {
        const values = clockState$.peek();
        const now = DateTime.now().toUnixInteger(); // this is in seconds, whereas _.now() is in ms!!
        const elapsed = now - values.start;
        clockState$.set({
            start: values.start,
            now,
            elapsed
        });
    };

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    renderComponent = (props) => {
        const {Component, bodyClasses} = props;
        // this basically gets render on each navigation...
        // structure it how you like..
        return (
            <div className={classnames(bodyClasses(props))}>
                <Component {...props} />
            </div>
        );
    };

    render() {
        // pass down a global clock, and current auth state..
        // helper logged_in prop that checks resolved
        const states = {
            clockState$
        };
        return <AdapterApp {...this.props} {...states} routes={routes} renderComponent={this.renderComponent} />;
    }
}

export default App;
