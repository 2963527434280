import {DateTime} from "luxon";

import {observable} from "@legendapp/state";

export const clockState$ = observable({
    start: DateTime.now().toUnixInteger(),
    now: DateTime.now().toUnixInteger(),
    elapsed: 0
});

export const newDataState = () => {
    return observable({});
};

export const userState$ = observable({
    following: {}
});
