import {observable} from "@legendapp/state";
import React from "react";
import Adapter from "./adapter";
import {bodyClasses} from "./scroll";

class AdapterApp extends React.Component {
    constructor(props) {
        super(props);
        this.appState$ = observable({});
    }

    render() {
        const {renderComponent} = this.props;

        const config = {
            enableScaling: true,
            ...this.props,
            render: renderComponent,
            appState$: this.appState$,
            bodyClasses
        };

        return <Adapter {...config} />;
    }
}

export default AdapterApp;
